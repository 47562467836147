import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section style={{marginTop: "50px"}}>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-12 text-center"}>
            <h1>404 - Not found</h1>
            <p>Sorry, you have a visited a page that does not exist.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
